/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Doka Notifications API
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetInboxTokenResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a token for the inbox
 */
export const getInboxToken = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetInboxTokenResponse>(
      {url: `/inbox/token`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInboxTokenQueryKey = () => {
    return [`/inbox/token`] as const;
    }

    
export const getGetInboxTokenQueryOptions = <TData = Awaited<ReturnType<typeof getInboxToken>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInboxToken>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInboxTokenQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInboxToken>>> = ({ signal }) => getInboxToken(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInboxToken>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetInboxTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getInboxToken>>>
export type GetInboxTokenQueryError = ErrorType<ProblemDetails>


export function useGetInboxToken<TData = Awaited<ReturnType<typeof getInboxToken>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInboxToken>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInboxToken>>,
          TError,
          Awaited<ReturnType<typeof getInboxToken>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetInboxToken<TData = Awaited<ReturnType<typeof getInboxToken>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInboxToken>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInboxToken>>,
          TError,
          Awaited<ReturnType<typeof getInboxToken>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetInboxToken<TData = Awaited<ReturnType<typeof getInboxToken>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInboxToken>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetInboxToken<TData = Awaited<ReturnType<typeof getInboxToken>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInboxToken>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetInboxTokenQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



