import { Button, Checkbox, Notification } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { OnboardingRequestModel } from '@shared/api/models';
import { getContractSigningLink } from '@shared/api/queries/contracts/contracts';
import { getGetStepsQueryKey } from '@shared/api/queries/steps/steps';
import { CompanyInformation } from '@shared/components/company/ViewCompany/CompanyInformation';
import { useQueryClient } from '@tanstack/react-query';
import { toastNotifications, variants } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { z } from 'zod';

import { i18n } from 'i18n';
type Props = {
    request: OnboardingRequestModel;
    className?: string;
    onClose: () => void;
};

const signContractSchema = z.object({
    iAgreeTheTermsAndConditions: z.boolean().refine((value) => value, {
        message: i18n.t('isRequired', { label: i18n.t('field') })
    }),
    iHaveReadAndUnderstood: z.boolean().refine((value) => value, {
        message: i18n.t('isRequired', { label: i18n.t('field') })
    })
});

export type SignContractSchema = z.infer<typeof signContractSchema>;

export const SignContractForm = ({ request, className, onClose }: Props) => {
    const { t } = useTranslation();
    const { isDirty, isValid, onSubmit, getInputProps, resetDirty } = useForm<SignContractSchema>({
        mode: 'controlled',
        validate: zodResolver(signContractSchema),
        validateInputOnChange: true,
        initialValues: { iAgreeTheTermsAndConditions: false, iHaveReadAndUnderstood: false }
    });

    const { requestId = '' } = useParams();
    const queryClient = useQueryClient();
    const [isSignatureLinkLoading, setIsSignatureLinkLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setIsSignatureLinkLoading(true);
            const request = await getContractSigningLink(requestId, undefined);
            resetDirty();
            queryClient.invalidateQueries({ queryKey: getGetStepsQueryKey(requestId) });
            toastNotifications.success({ title: t('success'), message: t('dataSaved', { data: t('signing') }) });
            if (request?.data) {
                window.open(request.data.link, '_self');
            }
            setIsSignatureLinkLoading(false);
            onClose();
        } catch {
            toastNotifications.error({ title: t('failed'), message: t('dataNotSaved', { data: t('signing') }) });
        }
    };

    return (
        <form className={clsx(className, 'flex flex-col gap-4')} onSubmit={onSubmit(handleSubmit)}>
            <Notification
                className="max-w-[912px]"
                classNames={{ description: 'flex flex-col' }}
                title={t('information')}
                withCloseButton={false}
            >
                {t('reviewCompanyDataInformation')}
            </Notification>
            <CompanyInformation mode="Frontend" request={request} />
            <Checkbox
                label={
                    <>
                        <span>{t('iAgreeTermsAndConditions')}</span>
                        <span className="text-text-alert">*</span>
                    </>
                }
                {...getInputProps('iAgreeTheTermsAndConditions')}
                required
            />
            <Checkbox
                label={
                    <>
                        <span>{t('iHaveReadAndUnderstood')}</span>
                        <span className="text-text-alert">*</span>
                    </>
                }
                {...getInputProps('iHaveReadAndUnderstood')}
                required
            />
            <div className="modal-footer mt-2">
                <Button className="modal-action" variant={variants.button.secondary} onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    className="modal-action"
                    disabled={!isDirty() || !isValid()}
                    loading={isSignatureLinkLoading}
                    type="submit"
                >
                    {t('signContract')}
                </Button>
            </div>
        </form>
    );
};
