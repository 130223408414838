/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Doka Notifications API
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetSenderImageResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the sender image
 */
export const getSenderImage = (
    trackingId: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetSenderImageResponse>(
      {url: `/messages/${trackingId}/sender-image`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSenderImageQueryKey = (trackingId: string,) => {
    return [`/messages/${trackingId}/sender-image`] as const;
    }

    
export const getGetSenderImageQueryOptions = <TData = Awaited<ReturnType<typeof getSenderImage>>, TError = ErrorType<ProblemDetails>>(trackingId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSenderImage>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSenderImageQueryKey(trackingId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSenderImage>>> = ({ signal }) => getSenderImage(trackingId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(trackingId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSenderImage>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetSenderImageQueryResult = NonNullable<Awaited<ReturnType<typeof getSenderImage>>>
export type GetSenderImageQueryError = ErrorType<ProblemDetails>


export function useGetSenderImage<TData = Awaited<ReturnType<typeof getSenderImage>>, TError = ErrorType<ProblemDetails>>(
 trackingId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSenderImage>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSenderImage>>,
          TError,
          Awaited<ReturnType<typeof getSenderImage>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSenderImage<TData = Awaited<ReturnType<typeof getSenderImage>>, TError = ErrorType<ProblemDetails>>(
 trackingId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSenderImage>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSenderImage>>,
          TError,
          Awaited<ReturnType<typeof getSenderImage>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSenderImage<TData = Awaited<ReturnType<typeof getSenderImage>>, TError = ErrorType<ProblemDetails>>(
 trackingId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSenderImage>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetSenderImage<TData = Awaited<ReturnType<typeof getSenderImage>>, TError = ErrorType<ProblemDetails>>(
 trackingId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSenderImage>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetSenderImageQueryOptions(trackingId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



